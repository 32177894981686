import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexContent = styled.div`
  display: grid;
  height: calc(100vh - 99px);
  grid-template-columns: 1fr;
  grid-template-rows: calc(100vh - 99px);
  overflow: hidden;
  
  .image-container {
    grid-column: 1/-1;
    grid-row: 1/-1;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  .text-container {
    grid-column: 1/-1;
    grid-row: 1/-1;
    z-index: 1;
    background-color: white;
    opacity: 90%;
    width: 670px;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    padding: 75px;
    height: fit-content;
    align-self: center;
    margin-left: 120px;

    a {
      color: #65B331;
    }
  }

  @media screen and (max-width: 1000px) {
      .text-container {
        margin: 0;
        align-self: initial;
        width: 100%;
        height: 100%;
        padding: 60px 40px;
        line-height: 1.1em;
      }
    }
`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <IndexContent>
      <div className="image-container">
        <img src="background.png" alt="logo" quality={100} layout="fullWidth"/>
      </div>
      <div className="text-container">
        <p>
          Du bist auf der Suche nach unseren Premium-Werkzeugen für Dach und Fassade?
          Dann bist Du schon fast am Ziel!
        </p>
        <p>
          Ab sofort findest Du unsere Premium Werkzeuge sowie spannende Geschichten 
          und Hintergründe zum Thema Dach und Fassade unter <a href="https://www.freund-cie.com/">www.freund-cie.com</a>
        </p>
        <p>Dein Freund-Team</p>
      </div>
    </IndexContent>
  </Layout>
)

export default IndexPage
